import InstagramImg from "../../assets/marketing/InstagramImg.svg";
import FacebookImg from "../../assets/marketing/FacebookImg.svg";
import GoogleImg from "../../assets/marketing/GoogleImg.svg";
import LinkdinImg from "../../assets/marketing/linkedin_ico.svg";

const SocialLinks = [
    {
        id: "1",
        link: "https://www.facebook.com/JRRealEstateMarketing/",
        image: `${FacebookImg}`,
        alt:"Facebook Img"
    },
    {
        id: "2",
        link: 'https://www.instagram.com/jrrealestatemarketing/',
        image: `${InstagramImg}`,
        alt:"Instagram Img"
    },
    {
        id: "3",
        link: 'https://www.google.com/search?q=The+Jane+Rothe+Co.&stick=H4sIAAAAAAAA_-NgU1I1qLAwsDA1NDZMTTVJNDRNSjK1MqgwSjJNNDO2MDIwSLZMNDAwXsQqFJKRquCVmJeqEJRfAmQ65-sBALp3CmY-AAAA&hl=en&mat=CaDtPXFtZ8vJElYBqiIDwkvOJ8hHvz0e72NSuZXbu7EcuRZq16mQNPNA2CcsqdLv5V7xyfrig2cu8QCbFDDGB7zBXnZS-Snzk_df0mMelFWMF9gu8B67E08A-3gITFNSfQ&authuser=0',
        image: `${GoogleImg}`,
        alt:"Google Img"
    },
    {
        id: "4",
        link: 'https://www.linkedin.com/company/the-jane-rothe-co',
        image: `${LinkdinImg}`,
        alt:"Linkdin Img"
    },
];
export default SocialLinks;