import React, { useEffect, useState } from "react";
import JoinHands from "../../assets/marketing/JoinHands.webp";
import DiscussionImg from "../../assets/marketing/DiscussionImg.webp";
import TeamDiscussionImg from "../../assets/marketing/TeamDiscussionImg.webp";
import LivinRoomImg from "../../assets/marketing/LivinRoomImg.webp";
import LearnMoreImg from "../../assets/marketing/LearnMoreImg.svg";
import KitchenImg from "../../assets/marketing/KitchenImg.webp";
import LandingPageImg from "../../assets/marketing/LandingPageImg.webp";
import SignUpThroughHome from "../pages/landingPage/SignUpThroughHome";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

const handleLearnMore = (sectionId) => {
  localStorage.setItem("scrollToSection", sectionId);
};

function LearnMoreBtn({section}){
 
  
  return (<div className="flex justify-end rounded-[24px]">
    <Link to="/about-us" onClick={() => handleLearnMore(section)} className="bg-clientNero rounded-[24px]">
      <img
        src={LearnMoreImg}
        alt="LearnMoreImg"
        className="w-[113px] h-[30px] rounded-[24px]"
      ></img>
    </Link>
  </div>)
}
const LandingSection = () => {
const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 2000,once: true });
  }, []);
  useEffect(() => {
    const sectionId = localStorage.getItem("scrollToSection");
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "instant" });
      }
      localStorage.removeItem("scrollToSection");
    }
  }, []);
  useEffect(() => {
    // Delay observer initialization by 2 seconds
    const timeout = setTimeout(() => {
      const element = document.getElementById('slide-up-element');
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        },
        { threshold: 0.5 }
      );

      if (element) {
        observer.observe(element);
      }

      return () => {
        if (element) observer.unobserve(element);
      };
    }, 1000); // 2-second delay

    return () => clearTimeout(timeout); // Clear timeout if component unmounts
  }, []);
  return (
    <>
      <div className="pt-[80px]">
        <div className="max-w-[1280px] h-auto relative mx-auto">
          <img
            src={LandingPageImg}
            alt="LandingPageImg"
            className="h-auto w-full m-[0]"
          ></img>
          <div
            className="absolute top-[115px] left-[75px] w-[45%]
            tablet:top-[50px]
            tablet:left-[30px]
            tablet:w-[60%]

            mobile:top-[20px]
            mobile:left-[20px]
            mobile:w-[50%]

            "
          >
            <div className="font-lejour text-[clamp(17px,_3vw,_27px)] font-normal leading-[47.25px] text-center text-clientBlack m-0 w-full uppercase mobile:leading-[20px]">
            <span
                key={1}
                className={`inline-block opacity-0 translate-y-4 animate-fade-in`}
                style={{ animationDelay: `${1 * 0.2}s` }}
              >
                {'J'}
            </span>
            <span
                key={2}
                className={`inline-block opacity-0 translate-y-4 animate-fade-in`}
                style={{ animationDelay: `${2 * 0.2}s` }}
              >
                {'A'}
            </span>
            <span
                key={3}
                className={`inline-block opacity-0 translate-y-4 animate-fade-in`}
                style={{ animationDelay: `${3 * 0.2}s` }}
              >
                {'N'}
            </span>
            <span
                key={4}
                className={`inline-block opacity-0 translate-y-4 animate-fade-in`}
                style={{ animationDelay: `${4 * 0.2}s` }}
              >
                {'e'}
            </span>
            {' '}
            <span
                key={5}
                className={`inline-block opacity-0 translate-y-4 animate-fade-in text-primary`}
                style={{ animationDelay: `${1 * 0.2}s` }}
              >
                {'R'}
            </span>
            <span
                key={6}
                className={`inline-block opacity-0 translate-y-4 animate-fade-in text-primary`}
                style={{ animationDelay: `${2 * 0.2}s` }}
              >
                {'O'}
            </span>
            <span
                key={7}
                className={`inline-block opacity-0 translate-y-4 animate-fade-in text-primary`}
                style={{ animationDelay: `${3 * 0.2}s` }}
              >
                {'T'}
            </span>
            <span
                key={8}
                className={`inline-block opacity-0 translate-y-4 animate-fade-in text-primary`}
                style={{ animationDelay: `${4 * 0.2}s` }}
              >
                {'h'}
            </span>
            <span
                key={9}
                className={`inline-block opacity-0 translate-y-4 animate-fade-in text-primary`}
                style={{ animationDelay: `${5 * 0.2}s` }}
              >
                {'e'}
            </span>
            </div>
          
            <p id="slide-up-element"
              className={`${
                isVisible ? 'animate-slideUp' : 'opacity-0' 
              }  text-[clamp(17px,_3vw,_27px)] font-glacial  font-normal leading-[47.25px] text-center text-clientBlack m-0 w-full uppercase mobile:leading-[20px] `}
            >
              Your marketing partner for building lasting connections and
              success
            </p>
          </div>
        </div>

        <div id="section1" data-aos="fade-up"
          className="max-w-[1280px] mx-auto px-[25px] pt-[55px]
          tablet:pl-[20px] tablet:pr-[20px]
          mobile:pl-[20px] mobile:pr-[20px] mobile:pt-[20px]
          "
        >
          <p className="font-lejour text-[clamp(25px,_3vw,_30px)] font-normal leading-[30px] text-clientBlack m-0 h-[30px]">
            WHAT WE DO
          </p>

          <div
            className="flex w-full justify-between  mt-[40px] mobile:mt-[20px]
          mobile:block"
          >
            <div  className="w-[47%] h-auto mobile:w-[100%] mobile:h-[100%]">
              <img
                src={JoinHands}
                alt="JoinHands"
                className="object-cover h-auto w-full rounded-[25px] m-[0] 
                mobile:w-[100%] mobile:h-[100%]
                "
              ></img>
            </div>

            <div  data-aos="fade-up"
              className="w-[49%] h-full text-justify
            mobile:w-[100%]
            mobile:ml-[0] mobile:mt-[20px]"
            >
              <h1 className="font-glacial text-left text-[clamp(20px,_3vw,_25px)]  font-normal leading-[32.5px] text-clientBlack m-0  break-words uppercase">
                Power of stories and connections
              </h1>
              <p className="font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[30.42px] text-clientBlack mt-[10px]  break-words ">
                At The Jane Rothe Co., we recognize that stories are more than
                just words—they are powerful tools that connect people, build
                trust, and keep you top of your mind. Our marketing approach is
                deeply rooted in storytelling, crafting narratives that resonate
                with potential clients and strengthen connections within your
                network so that when real estate needs arise, you’re the first
                person they think of and call.
              </p>
              {/* <LearnMoreBtn section="section1"/> */}
            </div>
          </div>
        </div>

        <div id="section2"  data-aos="fade-up"
          className="max-w-[1280px] mx-auto mt-[40px] mb-[40px] flex  items-baseline pr-[25px] 
         tablet:pr-[20px]
         mobile:pr-[0]
         mobile:mt-[20px] mobile:mb-[20px]
         "
        >
          <div
            className="ml-[25px] w-[31%] h-[6px] bg-primary mr-[40px]
           mobile:mr-[40px]"
          ></div>
          <div>
            <p className="font-glacial text-[clamp(20px,_3vw,_25px)] font-normal leading-[42.25px] text-clientBlack m-0 uppercase">
              Empowering Real Estate Agents to Build Lasting Relationships
            </p>
          </div>
        </div>

        <div data-aos="fade-up"
          className="max-w-[1280px] mx-auto  px-[25px]
          tablet:px-[0px]
          mobile:px-[0px]
        tablet:ml-[20px] tablet:mr-[20px]
        mobile:ml-[20px] mobile:mr-[20px] 
        "
        >
          <div
            className=" w-full flex justify-between 
           mobile:block
          "
          >
            <div 
              className="w-[49%] h-full text-justify  
             mobile:w-full"
            >
              <p className="font-glacial text-[clamp(16px,_3vw,_18px)]  font-normal leading-[30.42px] text-clientBlack break-words ">
                We believe that successful real estate marketing is built on
                relationships, not just transactions. At The Jane Rothe Co., we
                empower real estate agents by providing them with the tools,
                strategies, and insights to foster genuine, long-lasting
                connections with their clients. We focus on helping agents build
                trust and loyalty within their communities, ensuring they are
                well-positioned for repeat business and referrals. Our approach
                is about cultivating relationships that go beyond a single
                deal—relationships that lead to sustained growth and ongoing
                success.
              </p>
              {/* <LearnMoreBtn section="section2"/> */}
            </div>

            <div className="w-[47%] h-auto mobile:w-full mobile:h-full mobile:mt-[20px]">
              <img
                src={DiscussionImg}
                alt="DiscussionImg"
                className="object-cover h-auto w-full rounded-[25px] m-[0]
                mobile:w-[100%] mobile:h-[100%]
                "
              ></img>
            </div>
          </div>

          <div id="section3" data-aos="fade-up" className="mt-[40px] w-full items-center mobile:mt-[20px]">
            <div className="">
              <p className="font-glacial text-[clamp(20px,_3vw,_25px)] font-normal leading-[42.25px] text-clientBlack m-0 uppercase">
                Blending the Familiarity of Traditional Marketing with
                Cutting-Edge Strategies
              </p>
            </div>

            <div  className="">
              <p className="text-justify font-glacial text-[clamp(16px,_3vw,_18px)] font-normal leading-[32.76px] text-clientBlack m-0 break-words">
                Our approach combines the best of both worlds: the reliability
                of traditional marketing with the innovation of modern
                strategies. At The Jane Rothe Co., we leverage time-tested
                methods that build trust and familiarity while integrating
                fresh, forward-thinking techniques tailored to today’s
                fast-paced real estate market. By staying on top of emerging
                trends and technologies, we ensure that our clients are not just
                keeping up but leading the way in their marketing efforts,
                creating impactful connections that drive real results.
              </p>
              <LearnMoreBtn section="section3"/>
            </div>
          </div>

          <div data-aos="fade-up" className="mt-[40px] mobile:mt-[20px]">
            <img
              src={TeamDiscussionImg}
              alt="TeamDiscussionImg"
              className="h-[350px] w-full object-cover m-[0]
               tablet:h-full
               mobile:h-full"
            ></img>
          </div>

          <div id="section4" data-aos="fade-up" className="mt-[50px] mobile:mt-[20px]">
            <h1 className="font-lejour text-[clamp(25px,_3vw,_30px)] font-normal leading-[30px] text-justify  m-0 text-clientBlack">
            MOST POPULAR SERVICES
            </h1>

            <p className="font-glacial text-[clamp(16px,_3vw,_20px)] font-normal leading-[20px] text-justify  mt-[20px] text-clientDarkCharcoal">
              All services are monthly subscriptions. The monthly cost does not
              include the hard costs of marketing.
            </p>
          </div>

          <div  data-aos="fade-up"
            className="mt-[40px] flex items-center  w-full
            tablet:block 
            tablet:mt-[40px] 
            mobile:block 
            mobile:mt-[20px] "
          >
            <div
              className=" w-[60%] h-auto 
            tablet:w-[100%] tablet:h-full
            mobile:w-[100%] mobile:h-full mobile:mb-[20px]
            "
            >
              <img
                src={LivinRoomImg}
                alt="LivinRoomImg"
                className="h-auto w-full object-cover m-[0] rounded-[35px]"
              ></img>
            </div>

            <div data-aos="fade-up"
              className=" rounded-[24px] w-[50%] h-auto  bg-clientNero index pt-[40px] pl-[40px] break-words ml-[-90px] 
             tablet:relative
             tablet:ml-[0]
             tablet:mt-[40px]
             mobile:ml-[0]

             tablet:w-[100%]
             mobile:relative
             mobile:w-[100%]
             mobile:h-full
             mobile:pr-[10px]
             mobile:pl-[10px]
             "
            >
              <h2 className="font-glacial  text-[clamp(25px,_3vw,_35px)] font-normal leading-[35px] m-0 text-clientWhite uppercase">
                The Premier Package
              </h2>
              <div className="mt-[20px]">
                <p className="font-glacial text-[15px] font-normal leading-[25px] text-justify m-0 text-clientWhite">
                  <li>Best for Individual Agents</li>
                </p>
              </div>
              <div>
                <p className="font-glacial text-[15px] font-normal leading-[25px] text-justify m-0 text-clientWhite">
                  <li>Business Consultation & Growth Strategies</li>
                </p>
              </div>

              <div>
                <p className="font-glacial text-[15px] font-normal leading-[25px] text-justify m-0 text-clientWhite">
                  <li>Marketing Design & Execution</li>
                </p>
              </div>

              <div>
                <p className="font-glacial text-[15px] font-normal leading-[25px] text-justify m-0 text-clientWhite">
                  <li>Email Marketing</li>
                </p>
              </div>

              <div>
                <p className="font-glacial text-[15px] font-normal leading-[25px] text-justify m-0 text-clientWhite">
                  <li>Social Media Marketing</li>
                </p>
              </div>

              <div  className="flex justify-end rounded-[24px]">
                <Link onClick={() => handleLearnMore("section4")} to={'/services'}>
                  <img
                    src={LearnMoreImg}
                    alt="LearnMoreImg"
                    className="w-[113px] h-[30px] rounded-[24px]"
                  ></img>
                </Link>
              </div>
            </div>
          </div>

          <div id="section5"  data-aos="fade-up"
            className="mt-[40px]  flex items-center justify-end w-full relative z-[8]
          tablet:block 
          tablet:mt-[40px] 
          mobile:block 
          mobile:mt-[20px] 
          "
          >
            <div
              className=" rounded-[24px] w-[50%] h-auto  bg-clientNero z-10  pt-[30px] pl-[40px] pr-[5px] break-words mr-[-90px]
              tablet:relative
              tablet:mr-[0]

              tablet:w-[100%]
              mobile:relative
              mobile:w-[100%]
              mobile:h-full
              mobile:pr-[10px]
              mobile:pl-[10px]
              mobile:mr-[0]

             "
            >
              <h2 className="font-glacial text-[clamp(25px,_3vw,_35px)] font-normal leading-[35px] m-0 text-clientWhite uppercase">
                Brokerage Marketing & Strategy Package
              </h2>

              <div className="mt-[20px]">
                <p className="font-glacial text-[15px] font-normal leading-[25px] text-justify m-0 text-clientWhite">
                  <li>Best for boutique brokerages who are looking to grow</li>
                </p>
              </div>

              <div>
                <p className="font-glacial text-[15px] font-normal leading-[25px] text-justify m-0 text-clientWhite">
                  <li>Comprehensive Marketing Director Services</li>
                </p>
              </div>

              {/* <div>
                <p className="font-glacial text-[15px] font-normal leading-[25px] text-justify m-0 text-clientWhite">
                  <li>Comprehensive Marketing Director Services</li>
                </p>
              </div> */}

              <div className="flex justify-end rounded-[24px]">
                <Link  onClick={() => handleLearnMore("section5")} to={'/services'}>
                <img
                  src={LearnMoreImg}
                  alt="LearnMoreImg"
                  className="w-[113px] h-[30px] mt-[0] rounded-[24px]"
                ></img>
                </Link>
              </div>
            </div>

            <div
              className=" w-[60%] h-auto 
            tablet:w-full tablet:h-full
            mobile:w-full mobile:h-full
            tablet:mt-[40px]
            mobile:mt-[20px]
            "
            >
              <img
                src={KitchenImg}
                alt="KitchenImg"
                className="h-auto w-full object-cover m-[0] rounded-[35px]"
              ></img>
            </div>
          </div>
           <div data-aos="fade-up">
           <SignUpThroughHome  />
           </div>
        </div>
      </div>
    </>
  );
};

export default LandingSection;
